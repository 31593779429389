import type { FunctionComponent } from "react";
import { useMemo } from "react";

import { ClientSideSuspense } from "shared/components/common/client-side-suspense";

import type { AppBarVariant } from "scmp-app/components/app-bar/types";

import { DynamicHamburgerMenuDefault, DynamicHamburgerMenuMagazinesStyle } from "./dynamics";
import { Container } from "./styles";

export type Props = {
  className?: string;
  variant?: AppBarVariant;
};

export const HamburgerMenu: FunctionComponent<Props> = ({ className, variant }) => {
  const anchor = useMemo(() => {
    switch (variant) {
      case "scmp/generic-light":
      case "scmp/home":
      case "scmp/magazines-style":
      case "scmp/slim":
        return "left";
      default:
        return "right";
    }
  }, [variant]);

  const render = () => {
    switch (variant) {
      case "scmp/magazines-style":
        return <DynamicHamburgerMenuMagazinesStyle anchor={anchor} />;
      default:
        return <DynamicHamburgerMenuDefault anchor={anchor} />;
    }
  };

  return (
    <Container className={className}>
      <ClientSideSuspense>{render()}</ClientSideSuspense>
    </Container>
  );
};

HamburgerMenu.displayName = "HamburgerMenu";
